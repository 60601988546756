import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Title = makeShortcode("Title");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Title text="Projects" mdxType="Title" />
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://play.google.com/store/apps/details?id=io.aithe.aithe_9pose"
        }}>{`9Poses - AI Golf Swing Analysis (Android, iOS)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://apps.apple.com/tr/app/zank-remote-for-android-tv-box/id1567602430"
        }}>{`Zank Remote for Android TV Box (Android,iOS)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://play.google.com/store/apps/details?id=com.sec.android.gallery3d&hl=vi&gl=KR"
        }}>{`Photo Editor Application (Android - All Samsung Phones & Tablets)`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      